<template>
  <section>
    <div class="main-contents-wrapper">
      <div class="table-wrapper">
        <div class="sub-title">
          <h3>추천인 제한 수</h3>
        </div>
        <table class="mainTable">
          <tr>
            <th>{{ $t('table.head.type') }}</th>
            <th>퍼센트</th>
            <th>{{ $t('button.modify') }}</th>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td style="width: 10%">
              <div class="status-change-btn-wrap">
                <button class="btn-innerTable btn-status-change wait" type="button">수정</button>
              </div>
            </td>
          </tr>
        </table>
      </div>

      <div class="table-wrapper">
        <div class="sub-title">
          <h3>추천인 입금 보너스</h3>
        </div>
        <table class="mainTable">
          <tr>
            <th>{{ $t('table.head.type') }}</th>
            <th>퍼센트</th>
            <th>{{ $t('table.head.max') }}</th>
            <th>{{ $t('button.modify') }}</th>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td style="width: 10%">
              <div class="status-change-btn-wrap">
                <button class="btn-innerTable btn-status-change wait" type="button">수정</button>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
import { getGameUseYn, updateGameUseYn, getCode } from "@/api/member.js";
import { GAME_INFO_LIST } from "@/libs/constants";
import uiToggle from "@/components/ui/UiToggle";

export default {
  name: "EventRecommenderLimit",
  components: {
    uiToggle
  },
  data: function () {
    return {
      gameList: []
    };
  },
  methods: {
    async getGameList() {
      const reqData = {};
      const res = await getGameUseYn(reqData);
      const gameList = res.data.list;
      const _gameList = await gameList.filter(el => el.groupCode === "slot");
      return await _gameList;
    },
    async setGameInfoList() {
      this.gameList=[];
      const gameList = await this.getGameList();
      console.log(gameList);
      for (const loop of gameList) {
        const groupCode = loop.groupCode;
        const gameCode = loop.gameCode;
        const gTxtCode = GAME_INFO_LIST[groupCode][gameCode.toString()];
        if (gTxtCode) {
          const gameTextKey = this.$t(`gameList.${gTxtCode}`);
          if (loop.useYn === "Y") {
            loop.useYn = true;
          } else {
            loop.useYn = false;
          }
          const rateObj = {
            gameTxtKey: gameTextKey,
            gameCode: loop.gameCode,
            useYn: loop.useYn,
            groupCode: loop.groupCode
          };
          this.gameList.push(rateObj);
        }
      }
    },
    async setGameUseYn(gameCode,useYn) {
      console.log(gameCode)
      console.log(useYn)
      for(const item of this.gameList){
        if(item.gameCode===gameCode){
          item.useYn = !useYn
        }
      }
      let _useYn = !useYn
      if(_useYn){
        _useYn = "Y"
      }else{
        _useYn = "N"
      }
      const req = {
        gameCode: gameCode,
        useYn:_useYn
      };
      const res = await updateGameUseYn(req);
      console.log(res)
      if(res.resultCode!=="0"){
        alert('설정 실패. 다시 시도해주세요.');
      }else{
        // await this.setGameInfoList();
      }
    }
  },
  async created() {
    await this.setGameInfoList();
  }
};
</script>

<style scoped>
.main-sub-data-item {
  flex: 1 1 0;
  margin-right: 10px;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 3px 3px 12px 0px #d1d1d1;
  display: flex;
}
.main-sub-data-item > div {
  padding: 8px 0;
  text-align: center;
  box-sizing: border-box;
}
.sub-data-item-title {
  background: #ab2926;
  color: #fff;
  font-weight: 700;
  width: 35%;
}
.sub-data-item-content {
  font-weight: 700;
  text-align: right;
  width: 65%;
}
.main-sub-data-item:last-child {
  margin-right: 0;
}
.page-contents-wrap {
  margin-bottom: 10px;
  display: flex;
}
.page-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.btn-total-data {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  background: #2f4858;
  width: fit-content;
  border-radius: 3px;
  border: 2px solid #2f4858;
}
.btn-total-data:hover {
  color: #2f4858;
  background: #f1f1e6;
}
.btn-total-data > i {
  margin-right: 5px;
}
.btn-total-data > span {
  font-weight: 700;
  letter-spacing: 2px;
}
.table-wrapper{
  max-width: 350px;
  border: 0;
  margin-bottom: 30px;
}
.mainTable td:last-child{
  width:20%;
}
.sub-title {
    font-size: 1em;
    font-weight: 700;
    color: var(--main);
    height: 32px;
    position: relative;
    margin-bottom: 20px;
    border-bottom: 2px solid var(--main);
}
.sub-title > h3 {
    display: flex;
    height: 100%;
    align-items: center;
    width: 180px;
    font-size: 0.9em;
    border-start-end-radius: 10px;
    border-start-start-radius: 10px;
}
.sub-title > h3::before {
    content: '●';
    color: #18698b;
    margin-right: 7px;
}
</style>
